import React, {useEffect, useReducer} from 'react';
import {Container} from 'basis';
import {
  initialSoftQuoteState,
  PAGES,
  personalLoanSoftQuoteReducerGem
} from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteReducerGem';
import {SoftQuoteLayout} from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import {GemAboutYou} from '@/components/PersonalLoanSoftQuote/pages-gem/GemAboutYou';
import {GemEmployment} from '@/components/PersonalLoanSoftQuote/pages-gem/GemEmployment';
import {GemYourQuote} from '@/components/PersonalLoanSoftQuote/pages-gem/GemYourQuote';
import {TimeoutModalHandler} from '@/components/TimeoutModal/TimeoutModal';

const pageInfo = {
  [PAGES.ABOUT_YOU]: {
    heading: 'About You',
    screenComponent: GemAboutYou
  },
  [PAGES.EMPLOYMENT]: {
    heading: 'Employment',
    screenComponent: GemEmployment
  },
  [PAGES.YOUR_QUOTE]: {
    heading: 'Your Quote',
    screenComponent: GemYourQuote
  }
};

function SoftQuoteScreen({
                           screenComponent: ScreenComponent,
                           state,
                           dispatch,
                         }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ScreenComponent]);

  return (
    <Container>
      <ScreenComponent state={state} dispatch={dispatch}/>
      <TimeoutModalHandler isGem />
    </Container>
  );
}

function PersonalLoanSoftQuoteGem({props}) {
  const [state, dispatch] = useReducer(personalLoanSoftQuoteReducerGem, initialSoftQuoteState);
  const {page} = state;
  const {
    screenComponent,
  } = pageInfo[page];

  return (
    <SoftQuoteLayout props={props}>
      <SoftQuoteScreen screenComponent={screenComponent}
                       state={state}
                       dispatch={dispatch}/>
    </SoftQuoteLayout>
  );
}

export {PersonalLoanSoftQuoteGem};
