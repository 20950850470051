import React from 'react';
import {PersonalLoanSoftQuoteGem} from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteGem';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_METADATA } from '@/components/Metadata/Metadata.constants';
import {DatadogAppender} from '@/components/PersonalLoanSoftQuote/DatadogAppender';

const SoftQuoteGem = (props) => {
  return (
    <>
      <Metadata {...SOFTQUOTE_METADATA} />
      <DatadogAppender/>
      <PersonalLoanSoftQuoteGem props = {props}/>
    </>
  )
};
export default SoftQuoteGem;
